import { PropsWithChildren, ReactElement, useCallback, useMemo, useRef, useState } from 'react'
import { Tooltip as BaseTooltip, TooltipProvider, TooltipTrigger, TooltipContent } from '@/components/ui/tooltip'
import { cls, whisper } from '@/utils'
import { PoNVoid } from '@/types'
import { TooltipContentProps } from '@radix-ui/react-tooltip'
import { DEFAULT_TOOLTIP_DELAY_DURATION } from '@/constants'

export interface TooltipProps {
  trigger?: string | ReactElement
  closeOnClick?: boolean
  className?: string
  triggerClassName?: string
  open?: boolean
  align?: TooltipContentProps['align']
  side?: TooltipContentProps['side']
  delayDuration?: number
  onOpenChange?: (open: boolean) => PoNVoid
}

const Tooltip = ({
  className,
  trigger,
  triggerClassName,
  children,
  closeOnClick = true,
  delayDuration,
  open,
  align,
  side,
  onOpenChange,
}: PropsWithChildren<TooltipProps>) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [isHovering, setIsHovering] = useState(false)
  const timerRef = useRef<any>(null)
  const realDelayDuration = delayDuration ?? DEFAULT_TOOLTIP_DELAY_DURATION

  const handleOpenChange = useCallback(
    (open: boolean) => {
      const realOpen = closeOnClick ? open : open || isHovering
      setTooltipOpen(realOpen)
      onOpenChange?.(realOpen)
    },
    [onOpenChange, isHovering, closeOnClick],
  )

  const realOpen = useMemo(() => {
    if (isHovering && !closeOnClick) {
      return true
    }
    if (open !== undefined) {
      return open
    }
    return tooltipOpen
  }, [open, tooltipOpen, isHovering, closeOnClick])

  return (
    <TooltipProvider>
      <BaseTooltip open={realOpen} onOpenChange={handleOpenChange}>
        <TooltipTrigger
          className={cls(triggerClassName)}
          onMouseEnter={() => {
            if (timerRef.current) {
              clearTimeout(timerRef.current)
            }
            if (!closeOnClick) {
              timerRef.current = setTimeout(() => {
                setIsHovering(true)
                setTooltipOpen(true)
              }, realDelayDuration)
            }
          }}
          onMouseLeave={() => {
            if (timerRef.current) {
              clearTimeout(timerRef.current)
            }
            timerRef.current = setTimeout(() => {
              setIsHovering(false)
              // setTooltipOpen(false)
            }, realDelayDuration)
          }}
        >
          {trigger}
        </TooltipTrigger>
        <TooltipContent
          className={cls('flex flex-col gap-1 w-max max-w-50 text-left', className)}
          align={align}
          side={side}
        >
          {children}
        </TooltipContent>
      </BaseTooltip>
    </TooltipProvider>
  )
}

export default Tooltip
